<template>
	<div class="Login flex align-center justify-center">
		<div class="container flex">
			<img class="left" src="@/assets/imgs/login-con-bg.png" />
			<div class="right br16">
				<div class="top pr00 text-c font-33 fw-7 fs20 pb24">
					{{ topText }}
				</div>

				<!-- 密码登录 start -->
				<div v-if="type == 1" class="type-1 type-wrap pt8">
					<div class="input-wrap flex align-center mt32">
						<img src="@/assets/icon/phone.png" />
						<input
							v-model="mobile"
							maxlength="11"
							class="fs16"
							type="text"
							placeholder="请输入手机号码"
							@keyup.enter="getVerify"
						/>
					</div>
					<div class="input-wrap flex align-center mt32">
						<img src="@/assets/icon/password.png" />
						<input
							v-model="password"
							class="fs16"
							maxlength="12"
							:type="pwdType ? 'password' : 'text'"
							placeholder="登录密码（6~12位数字+字母）"
							@keyup.enter="btn"
						/>
						<img
							@click="pwdType = !pwdType"
							class="eye"
							:src="
								pwdType
									? require('@/assets/icon/eye-close.png')
									: require('@/assets/icon/eye-open.png')
							"
						/>
					</div>
					<div class="jump text-r pt32 font-66">
						<span @click="type = 2">验证码登录</span>
					</div>
				</div>
				<!-- 密码登录 end -->

				<!-- 验证码登录 start -->
				<div v-if="type == 2" class="type-2 type-wrap pt8">
					<div class="input-wrap flex align-center mt32">
						<img src="@/assets/icon/phone.png" />
						<input
							v-model="mobile"
							maxlength="11"
							class="fs16"
							type="text"
							placeholder="请输入手机号码"
							@keyup.enter="getVerify"
						/>
					</div>
					<div class="input-wrap code flex align-center mt32">
						<div class="flex align-center">
							<img src="@/assets/icon/ver.png" />
							<input
								v-model="code"
								class="fs16"
								maxlength="6"
								type="text"
								placeholder="请输入验证码"
								@keyup.enter="btn"
							/>
						</div>
						<button @click="getVerify">
							{{ codeText }}
						</button>
					</div>
					<div class="jump text-r pt32 font-66">
						<span @click="type = 1">密码登录</span>
					</div>
				</div>
				<!-- 验证码登录 end -->

				<!-- 注册账号 忘记密码 start -->
				<div v-if="type == 3 || type == 4" class="type-3 type-wrap pt8">
					<div class="input-wrap flex align-center mt32">
						<img src="@/assets/icon/phone.png" />
						<input
							v-model="mobile"
							maxlength="11"
							class="fs16"
							type="text"
							placeholder="请输入手机号码"
							@keyup.enter="getVerify"
						/>
					</div>
					<div class="input-wrap code flex align-center mt32">
						<div class="flex align-center">
							<img src="@/assets/icon/ver.png" />
							<input
								v-model="code"
								class="fs16"
								maxlength="6"
								type="text"
								placeholder="请输入验证码"
								@keyup.enter="btn"
							/>
						</div>
						<button @click="getVerify">
							{{ codeText }}
						</button>
					</div>
					<div class="input-wrap flex align-center mt32">
						<img src="@/assets/icon/password.png" />
						<input
							v-model="password"
							class="fs16"
							maxlength="12"
							:type="pwdType ? 'password' : 'text'"
							placeholder="设置登录密码（6~12位数字+字母）"
							@keyup.enter="btn"
						/>
						<img
							@click="pwdType = !pwdType"
							class="eye"
							:src="
								pwdType
									? require('@/assets/icon/eye-close.png')
									: require('@/assets/icon/eye-open.png')
							"
						/>
					</div>
					<div v-if="type == 4" class="input-wrap flex align-center mt32">
						<img src="@/assets/icon/password.png" />
						<input
							v-model="invite_code"
							class="fs16"
							type="text"
							placeholder="请输入邀请码（选填）"
							@keyup.enter="btn"
						/>
					</div>
					<div v-if="type == 3" class="jump text-r pt32 font-66">
						<span @click="type = 1">密码登录</span>
					</div>
				</div>
				<!-- 注册账号 忘记密码 end -->

				<!-- 绑定微信 start -->
				<div v-if="type == 5" class="type-5 type-wrap pt8">
					<div class="input-wrap flex align-center mt32">
						<img src="@/assets/icon/phone.png" />
						<input
							v-model="mobile"
							maxlength="11"
							class="fs16"
							type="text"
							placeholder="请输入手机号码"
							@keyup.enter="getVerify"
						/>
					</div>
					<div class="input-wrap code flex align-center mt32">
						<div class="flex align-center">
							<img src="@/assets/icon/ver.png" />
							<input
								v-model="code"
								class="fs16"
								maxlength="6"
								type="text"
								placeholder="请输入验证码"
								@keyup.enter="btn"
							/>
						</div>
						<button @click="getVerify">
							{{ codeText }}
						</button>
					</div>
					<div class="input-wrap flex align-center mt32">
						<img src="@/assets/icon/password.png" />
						<input
							v-model="password"
							class="fs16"
							maxlength="12"
							:type="pwdType ? 'password' : 'text'"
							placeholder="设置登录密码（6~12位数字+字母）"
							@keyup.enter="btn"
						/>
						<img
							@click="pwdType = !pwdType"
							class="eye"
							:src="
								pwdType
									? require('@/assets/icon/eye-close.png')
									: require('@/assets/icon/eye-open.png')
							"
						/>
					</div>
				</div>
				<!-- 绑定微信 end -->

				<el-button
					class="login-btn bglr font-ff mt32"
					type="text"
					@click="btn"
					:loading="loading"
					>{{ btnText }}</el-button
				>

				<!-- 其他 start -->
				<div v-if="type == 1 || type == 2" class="other-wrap">
					<div class="other pt24 flex align-center justify-center">
						<span @click="type = 3" class="forget font-33">忘记密码？</span
						><span class="line mlr16 font-99">|</span
						><span @click="type = 4" class="reg">注册账号&gt;</span>
					</div>
					<div class="wx text-c">
						<div>快捷登录</div>
						<img @click="wxBtn" class="mt16" src="@/assets/icon/wx.png" />
					</div>
				</div>

				<div v-if="type == 4" class="other-wrap">
					<div class="other pt24 flex align-center justify-center">
						<span @click="type = 3" class="forget font-33">忘记密码？</span
						><span class="line mlr16 font-99">|</span
						><span @click="type = 1" class="reg">登录</span>
					</div>

					<div class="reg-info fs12 text-c">
						点击注册即默认同意
						<a
							target="_blank"
							href="https://app.haohaobangcn.com/agreement/user.html"
						>
							<span>《用户注册协议》</span></a
						>
					</div>
				</div>
				<!-- 其他 end -->

				<div
					v-if="type == 5"
					class="wx-cancel flex align-center justify-center"
				>
					<span @click="type = 1">取消</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Login",
	components: {},
	data() {
		return {
			type: 1, // 1 密码登录; 2 验证码登录; 3 忘记密码; 4 注册账号; 5 绑定微信登录;
			codeLoading: false, // 是否发送验证码请求中
			loading: false, // 是否请求数据中
			Timer: null, // 定时器
			date: 60, // 时间
			pwdType: true, // 密码状态 true 密码状态; false 文字;

			mobile: "", // 手机号
			code: "", // 验证码
			invite_code: "", // 邀请码
			password: "", // 密码

			WxLoginTimer: null, // 微信登录轮询定时器
			WxLoginClearTimer: false, //  是否清除定时器轮询
			wxUnique: "", // 轮询查看的时候 唯一标识
			wxUserinfo: "", // 微信未绑定数据
		};
	},
	watch: {
		type: {
			handler() {
				// this.WxLoginClearTimer = true;
				// clearTimeout(this.Timer);
				this.date = 60;
				this.code = "";
				this.password = "";
				this.invite_code = "";
				this.pwdType = true;
			},
		},
	},
	computed: {
		// 顶部 top 字
		topText() {
			let { type } = this;
			if (type == 4) {
				return "注册账号";
			}

			if (type == 3) {
				return "忘记密码";
			}

			return "欢迎登录";
		},
		// 登录按钮 字
		btnText() {
			let { type } = this;
			if (type == 4) {
				return "注册";
			}

			if (type == 3) {
				return "重置";
			}

			return "登录";
		},
		codeText() {
			let { date } = this;
			if (date == 60) {
				return "获取验证码";
			} else {
				return date + "S 后重新获取";
			}
		},
	},
	destroyed() {
		this.WxLoginClearTimer = true;
		clearTimeout(this.WxLoginTimer);
	},
	methods: {
		// 微信登录的处理
		wxLoginHandle(data) {
			// status -1 微信登陆报错 0 微信用户不存在 1 微信用户存在
			switch (data.status) {
				case -1:
					this.$message.error("请刷新页面，重新登录");
					break;
				case 0:
					this.$message.warning("请绑定手机号");
					this.wxUserinfo = JSON.stringify(data.userinfo);
					this.type = 5;
					break;
				case 1:
					this.loginFun({
						code: 1,
						data,
					});
					break;
			}
		},
		// 微信登录定时器
		wxCallback() {
			clearTimeout(this.WxLoginTimer);
			if (this.wxUnique && !this.WxLoginClearTimer) {
				this.WxLoginTimer = setTimeout(() => {
					clearTimeout(this.WxLoginTimer);
					this.$API
						.wxLoginCallback({
							state: this.wxUnique,
						})
						.then((res) => {
							if (res.code == 1) {
								if (res.data.is_login) {
									this.wxUnique = "";
									this.wxLoginHandle(res.data);
									this.WxLoginClearTimer = true;
									clearTimeout(this.WxLoginTimer);
								} else {
									!this.WxLoginClearTimer && this.wxCallback();
								}
							} else {
								console.log(res.msg);
								this.WxLoginClearTimer = true;
							}
						})
						.catch((err) => {
							console.log("err", err);
							this.WxLoginClearTimer = true;
						});
				}, 2000);
			}
		},
		// 微信登录
		wxBtn() {
			var iWidth = 1000;
			var iHeight = 500;
			var iTop = (window.screen.availHeight - 30 - iHeight) / 2; //获得窗口的垂直位置;
			var iLeft = (window.screen.availWidth - 10 - iWidth) / 2; //获得窗口的水平位置;

			this.$API
				.wxGetUrl()
				.then((res) => {
					if (res.code == 1) {
						this.wxUnique = res.data.state;
						window.open(
							res.data.url,
							"_blank ",
							"width=1000,height=500,top=" +
								iTop +
								",left=" +
								iLeft +
								"menubar=yes,location=yes,resizable=yes,scrollbars=true,status=true",
							true
						);

						this.wxCallback();
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					this.codeLoading = false;
					console.log("err", err);
				});
		},
		// 获取验证码
		getVerify() {
			let { mobile, codeLoading, date } = this;

			if (!mobile || !this.$validate(mobile, "phone")) {
				this.$message.warning("请输入正确的手机号");
				return false;
			}

			if (codeLoading || date != 60) {
				return false;
			}
			this.codeLoading = true;

			let type = 3;

			if (this.type == 3) {
				type = 2;
			}
			if (this.type == 4) {
				type = 1;
			}
			if (this.type == 5) {
				type = 3;
			}

			this.$API
				.SendVerifyCode({
					mobile,
					type,
				})
				.then((res) => {
					if (res.code == 1) {
						this.Timer = setInterval(() => {
							this.date = this.date - 1;

							if (this.date <= 0) {
								this.date = 0;
								clearInterval(this.Timer);
								this.date = 60;
							}
						}, 1000);

						if (this.$store.state.ISDEV == 1) {
							this.code = res.data.code.toString();
						}

						this.$message.success("发送成功");
						this.codeLoading = false;
					} else {
						this.$message.error(res.msg);
						this.codeLoading = false;
					}
				})
				.catch((err) => {
					this.codeLoading = false;
					console.log("err", err);
				});
		},
		// 点击按钮
		btn() {
			let { mobile, loading, type } = this;

			if (loading) {
				return false;
			}
			if (!mobile || !this.$validate(mobile, "phone")) {
				this.$message.warning("请输入正确的手机号");
				return false;
			}
			switch (type) {
				case 1: // 密码登录
					this.loginPaw();
					break;
				case 2: // 验证码登录
					this.login();
					break;
				case 3: // 忘记密码
					this.forget();
					break;
				case 4: // 注册账号
					this.register();
					break;
				case 5: // 绑定微信登录
					this.bindWx();
					break;
			}
		},
		bindWx() {
			let {
				mobile, // 手机号
				code, // 验证码
				password, // 密码
				wxUserinfo,
			} = this;
			console.log("wxUserinfo", wxUserinfo);

			if (!code || code.length != 6) {
				this.$message.warning("请输入正确的验证码");
				return false;
			}
			if (!password || !this.$validate(password, "password")) {
				this.$message.info("请输入字母加数字6-12位密码");
				return false;
			}

			this.loading = true;
			this.$API
				.bindWx({
					wxinfo: wxUserinfo,
					mobile,
					code,
					type: 3,
					password,
				})
				.then((res) => {
					console.log("res", res);
					this.loginFun(res);
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		// 忘记密码
		forget() {
			let {
				mobile, // 手机号
				code, // 验证码
				password, // 密码
			} = this;

			if (!code || code.length != 6) {
				this.$message.warning("请输入正确的验证码");
				return false;
			}
			if (!password || !this.$validate(password, "password")) {
				this.$message.info("请输入字母加数字6-12位密码");
				return false;
			}

			this.loading = true;
			this.$API
				.forget({
					mobile,
					code,
					password,
				})
				.then((res) => {
					console.log("res", res);
					this.loading = false;
					if (res.code == 1) {
						this.$message.success("重置密码完成");
						this.type = 1;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		// 验证码登录
		login() {
			let {
				mobile, // 手机号
				code, // 验证码
			} = this;

			if (!code || code.length != 6) {
				this.$message.warning("请输入正确的验证码");
				return false;
			}

			this.loading = true;
			this.$API
				.Login({
					mobile,
					code,
				})
				.then((res) => {
					console.log("res", res);
					this.loginFun(res);
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		// 密码登录
		loginPaw() {
			let {
				mobile, // 手机号
				password, // 密码
			} = this;

			if (!password || !this.$validate(password, "password")) {
				this.$message.info("请输入字母加数字6-12位密码");
				return false;
			}

			this.loading = true;

			this.$API
				.LoginPaw({
					mobile,
					password,
				})
				.then((res) => {
					console.log("res", res);
					this.loginFun(res);
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		// 注册
		register() {
			let {
				mobile, // 手机号
				code, // 验证码
				invite_code, // 邀请码
				password, // 密码
			} = this;

			if (!code || code.length != 6) {
				this.$message.warning("请输入正确的验证码");
				return false;
			}
			if (!password || !this.$validate(password, "password")) {
				this.$message.info("请输入字母加数字6-12位密码");
				return false;
			}

			this.loading = true;
			this.$API
				.register({
					mobile,
					code,
					type: 1,
					password,
					invite_code,
				})
				.then((res) => {
					console.log("res", res);
					this.loginFun(res);
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		// 登录 函数
		loginFun(res) {
			if (res.code == 1) {
				this.$message.success("登录成功");

				this.Timer = setTimeout(() => {
					this.$store.commit("login", res.data.userinfo);

					this.loading = false;
					this.$router.push({ name: "Index" });

					this.$API
						.getUserInfo({
							user_id: res.data.userinfo.id,
						})
						.then((ress) => {
							if (ress.code == 1) {
								let obj = { ...res.data.userinfo, ...ress.data };
								this.$store.commit("login", obj);
							}
						})
						.catch((err) => {
							console.log("err", err);
						});

					clearTimeout(this.Timer);
				}, 800);
			} else {
				this.$message.error(res.msg);
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped lang="scss">
.Login {
	width: 100%;
	padding-top: 56px;
	padding-bottom: 60px;
	background: #ffffff;

	.container {
		.left {
			margin-top: 48px;
			width: 440px;
			height: 440px;
		}

		.right {
			margin-left: 80px;
			width: 530px;
			background: rgba(255, 255, 255, 0.39);
			border: 2px solid #eeeeee;
			overflow: hidden;
			padding: 0 48px 44px 48px;

			.top {
				padding-top: 40px;

				&:after {
					content: "";
					display: block;
					width: 64px;
					height: 2px;
					background: #ff8300;
					border-radius: 1px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}

			.type-wrap {
				.input-wrap {
					background: #f6f7f9;
					border-radius: 24px;
					padding: 14px 24px;

					.iconfont {
						color: #c6ccda;
						font-size: 22px;
					}

					img {
						width: 16px;
						height: 20px;
					}

					input {
						flex: 1;
						margin-left: 16px;
					}

					.eye {
						cursor: pointer;
						width: 18px;
						height: auto;
					}

					&.code {
						padding: 0;
						background-color: transparent;

						> div {
							background: #f6f7f9;
							border-radius: 24px;
							padding: 14px 24px;
							flex: 1;
						}

						button {
							margin-left: 16px;
							width: 106px;
							height: 48px;
							background: #ff8300;
							border-radius: 24px;
							font-size: 14px;
							font-weight: 400;
							color: #ffffff;
						}
					}
				}

				.jump {
					span {
						user-select: none;
						cursor: pointer;
					}
				}
			}

			.login-btn {
				width: 100%;
				cursor: pointer;
				height: 48px;
				background: #ff8300;
				border-radius: 24px;
				color: #fff !important;
				font-size: 16px;
				font-weight: 400;

				&:hover {
					color: #fff !important;
				}

				span {
					&:hover {
						color: #fff !important;
					}
				}
			}

			.other-wrap {
				.other {
					.forget {
						cursor: pointer;
					}

					.reg {
						cursor: pointer;
						color: #3491ff;
					}
				}

				.wx {
					padding-top: 48px;

					img {
						cursor: pointer;
						width: 49px;
						height: 49px;
					}
				}

				.reg-info {
					margin-top: 40px;
					color: #666666;

					span {
						cursor: pointer;
						color: #4177dd;
					}
				}
			}

			.wx-cancel {
				margin-top: 18px;
				width: 100%;
				height: 48px;
				border-radius: 24px;
				font-size: 16px;
				font-weight: 400;

				span {
					color: #666666;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
